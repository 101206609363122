import './App.css';
import Home from './Home/Home';

function App() {

  return (
    <div className="website-background">
      <div className="background-container">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="website-main-elements">
          <Home />
        </div >
      </div >
    </div >


  );
}

export default App;

