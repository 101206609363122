import React from "react";
import './landing.css';


function Landing() {
    return (
        <section id='landing' className="landing">
            <div className="landing-elements">
                <div className="landing-title">Orbilaw </div>
                <div className="landing-middle">Succession planning & digital assets</div>
                <div className="landing-button">Coming soon</div>

            </div>
        </section>

    );
}

export default Landing;
